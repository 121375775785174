<template>
    <div>
        <div class="content-header row">
            <div class="content-header-left col-md-10 col-12 mb-2">
                <div class="row breadcrumbs-top">
                    <div class="col-12">
                        <div class="breadcrumb-wrapper">

                            <h4 class="content-header-title float-left pr-1 mb-0">
<router-link to="/asb_kesesuaian_ssh/list_dinas">
                                <div type="button" class="btn btn-primary btn-sm">
                                 
                                    <feather-icon icon="GridIcon" size="20" class="" /> 
                                </div>

                                {{ dinas.nama_dinas }}
                                
                        </router-link>
                            </h4>

                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">

                                </li>

                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-header-right text-md-right col-md-2 col-12 d-md-block d-none">
                <router-link to="/asb_kesesuaian_ssh/list_dinas"><button type="button"
                        class="btn btn-primary btn-block waves-effect waves-float waves-light" v-b-modal.modal-lg
                        variant="outline-primary"><i class="fa fa-list mr-1"></i>Daftar Dinas </button></router-link>
            </div>
        </div>
        <div>
            <router-view></router-view>
        </div>



    </div>

</template>

<script>
    import {
        mapMutations,
        mapState
    } from 'vuex'
    import Base from '@/config/Mixins_base';
    import axios from '@/config/Axios';
    import Ripple from 'vue-ripple-directive'
    import {
        BFormSelect,

        BFormInput,
        BFormGroup,
        BRow,
        BCol,
        BPagination
    } from 'bootstrap-vue'

    export default {
        components: {

            BFormSelect,
            BFormInput,
            BFormGroup,
            BRow,
            BCol,
            BPagination
        },

        data() {
            return {
                currentPage: 1,
                rows: 0,
                cari: ""
            }
        },
        mixins: [Base],

        directives: {
            Ripple,
        },
        watch: {
            cari(newData, old) {
                if (newData != NaN) {
                    this.load_data(this.cari, this.currentPage);
                }
            },
            currentPage(newData, old) {
                if (newData != NaN) {
                    this.load_data(this.cari, this.currentPage);
                }
            },
        },
        computed: {
            ...mapState({
                rs_dinas: state => state.assh.rs_dinas,
                dinas: state => state.assh.dinas,
                rs_sub_kegiatan: state => state.assh.rs_sub_kegiatan,
                sub_kegiatan: state => state.assh.sub_kegiatan,
                count_data: state => state.assh.count_data,
            }),
        },
        mounted() {},
        methods: {
            ...mapMutations({
                set_rs_dinas_ssh: 'set_rs_dinas_ssh',
                set_dinas_ssh: 'dinas',
                set_rs_sub_kegiatan_ssh: "set_rs_sub_kegiatan_ssh",
                set_sub_kegiatan_ssh: "set_sub_kegiatan_ssh",
                set_count_data_ssh: 'set_count_data_ssh'
            }),
            detailSubKegiatan(val) {
                this.set_sub_kegiatan_ssh(val);
            },
            async load_data(cari, offset) {},
            async load_count() {},
        }
    }
</script>

<style>

</style>